.uploadImage{
    color: rgb(107 114 128);
    
}
.uploadImage:hover{
     color: rgb(55 65 81); 
}
.cloudIcon{
    color: rgb(107 114 128);
    font-size: 1.875rem ;
    line-height: 2.25rem;
}
.cloudIcon:hover{
    color: rgb(55 65 81);
}