.notifpanes {
border:solid 0.2px; 
    /* border: solid 0.5px; */
    border-radius: 5px;
    border-top-right-radius: 5px;
    margin-right: 0 !important;
    margin-left: 0 !important;
    box-shadow: 0 1.5px 3px 0 rgb(0 0 0 / 16%);
    background: var(--white);
}
.notf-for-bills {
    border-left: 3px solid #024172;
}
.notf-for-others {
    border-left: 3px solid #369144;
}
.notf-for-payment {
    border-left: 3px solid #4156a6;
}
.pt1 {
    padding:1rem;
}
.notf-date {
    color: #b8b8b8;
    font-size: 13px;
}
.breakers {
    height: 2rem;
    background: url(../../img/elements/even.svg) no-repeat;
    background-position: 8% 50%;
}

.css-19kzrtu {
    padding: 24px 0px!important;
}

