  .auth-header .navbar .nav .nav-item .nav-link-def, .auth-header .navbar .nav .nav-item .nav-link-shy{
    display: none;
  }

  .auth-header .navbar .nav .nav-item {
    margin-right: 0px;}

  .auth-header .nav-link-def {
    color: var(--white);
    background: var(--water-blue);
    text-transform: uppercase;
    padding: 8px 3rem 5px 3rem;
    border-radius: 3px;
    border: none;
    display: inline-block;
    display: inline-block;
    -webkit-transition: all .3s ease 0s;
    -moz-transition: all .3s ease 0s;
    -o-transition: all .3s ease 0s;
    transition: all .3s ease 0s;
  }

  .nav-link-light {
    color: rgba(0, 0, 0, 0.26);
    background-color: rgba(0, 0, 0, 0.12);
    padding: 8px 3rem 5px 3rem;
    border-radius: 3px;
    border: none;
    display: inline-block;
    display: inline-block;
    -webkit-transition: all .3s ease 0s;
    -moz-transition: all .3s ease 0s;
    -o-transition: all .3s ease 0s;
    transition: all .3s ease 0s;
  }
  .disabled {
    cursor: not-allowed;
  }

  .nav-link-shy {
    color: var(--water-blue);
    background: var(--white);
    text-transform: uppercase;
    padding: 8px 3rem 5px 3rem;
    border-radius: 3px;
    border: solid 1px var(--golden);
    display: inline-block;
    display: inline-block;
    -webkit-transition: all .3s ease 0s;
    -moz-transition: all .3s ease 0s;
    -o-transition: all .3s ease 0s;
    transition: all .3s ease 0s;
  }

    .nav-item .active{
    border-bottom: 3px solid var(--water-blue);
  }

  .forgot-pass {
    margin: 1.25rem 0;
  }
  .forgot-pass a{
    color: var(--water-blue);
  }