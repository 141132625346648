@import url("https://fonts.googleapis.com/css?family=Arial:100,300,400,500,700,900&display=swap");

:root {
    /*--water-blue: #4156A6;*/
    /*--brownish-grey: #636363;*/
    --water-blue: #412A21;
    --brownish-grey: #FFBA00;
    --water-light: #4156A6;
    --golden: #369144;
    --white: #ffffff;
    --warm-grey: #707070;
    --charcoal: #343434;
    --black: #000000;
    --greyish: #b8b8b8;
    --prussian-blue: #024172;
    --silver: #e4e4e4;
    --smoke: #fafafa;
    --smokelight: #f5f5f5
}

* {
    transition: ease .3s
}

.proxima {
    font-family: 'Proxima Nova', sans-serif
}

.w7 {
    font-weight: 700
}

body {
    color: var(--warm-grey);
    font-family: Arial, sans-serif;
    font-size: 15px;
    font-weight: 400;

    line-height: 1.667
}

.container {
    height: 100% !important
}

ul {
    list-style-type: none;
    margin: 0;
    padding: 0
}

a {
    font-family: 'Proxima Nova', sans-serif;
    text-transform: uppercase
}

a:hover {
    text-decoration: none
}

button {
    cursor: pointer
}

button:focus {
    outline: 0 !important;
    box-shadow: none !important
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Proxima Nova', sans-serif;
    font-weight: 700;
    color: #2a2a2a;
    line-height: 1.25
}

.h1,
h1 {
    font-size: 50px
}

.h2,
h2 {
    font-size: 26px
}

.for-readings {
    border-top: 1px solid var(--silver);
    border-right: 1px solid var(--silver);
    border-left: 1px solid var(--silver);
    border-bottom: 1px solid transparent;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    margin-right: 0 !important;
    margin-left: 0 !important
}

.for-readings2 {
    border-top: 1px solid var(--silver);
    border-right: 1px solid var(--silver);
    border-left: 1px solid var(--silver);
    border-bottom: 1px solid var(--silver);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    margin-right: 0 !important;
    margin-left: 0 !important
}

.for-billing {
    box-shadow: 0 1.5px 3px 0 rgba(0, 0, 0, .16);
    margin-right: 0 !important;
    margin-left: 0 !important;
    border-radius: 5px
}

.card-footer {
    border-bottom: 1px solid var(--silver);
    border-right: 1px solid var(--silver);
    border-left: 1px solid var(--silver);
    border-top: 1px solid transparent
}

.readings-read-more {
    background: var(--white) url(../../assets/img/elements/arrow-dropdown.svg) no-repeat;
    background-position: 93% 50%;
    border: none !important
}

.water-blue {
    color: var(--water-blue)
}

.prussian-blue {
    background: var(--prussian-blue)
}

.presentation-tab .nav-link.active {
    background: var(--prussian-blue) !important
}

.presentation-tab .nav-link {
    background: var(--greyish) !important;
    color: var(--silver)
}

.presentation-tab:first-child {
    margin-right: 0 !important;
    margin-left: -15px !important
}

.presentation-tab:last-child {
    margin-right: -15px !important;
    margin-left: 0 !important
}

.wizard-head {
    border-bottom: 1px solid rgba(23, 135, 224, .21)
}

.t-big {
    font-size: 1.2em
}

.smoke {
    background: var(--smoke)
}

.smoke h5 {
    margin-top: 2rem
}

.dash-intro {
    font-family: Proxima Nova, sans-serif;
    font-weight: 700;
    padding-top: 1em
}

.bowser-summary {
    background: rgba(23, 135, 224, .19)
}

.accounts {
    border-radius: 5px;
    box-shadow: 1.5px 2px 5.5px 0 rgba(23, 135, 224, .5);
    background: var(--white) url(../../assets/img/dropdown-circle.svg) no-repeat;
    background-position: 93% 50%
}

.ac-delete a {
    font-size: 2em;
    color: var(--greyish)
}

.accounts-profile {
    border-radius: 5px;
    box-shadow: 1.5px 2px 5.5px 0 rgba(23, 135, 224, .5)
}

.ac-avatar img {
    margin-top: 50%;
    margin-left: 25px
}

.liners {
    border-bottom: 1px solid var(--silver)
}

.liners:last-child {
    border: none
}

.liners-result {
    margin-top: 1rem;
    font-family: Proxima Nova, sans-serif;
    font-weight: 400
}

.liners-result h2 {
    margin-top: 1rem;
    font-family: Proxima Nova, sans-serif;
    font-weight: 400
}

.pay-summary {
    font-family: Proxima Nova, sans-serif;
    font-weight: 700;
    color: var(--water-blue);
    margin-top: 2rem
}

.ac-info {
    padding: 1rem 0 .7rem 25px
}

.ac-info p {
    margin-bottom: .4rem
}

.ac-info span {
    color: var(--warm-grey)
}

.ac-details {
    color: var(--water-blue)
}

.pay-methods {
    margin-top: 2rem
}

.full-width-row {
    margin-right: -15px;
    margin-left: -15px;
    width: 100%
}

.r-date {
    color: var(--greyish);
    font-size: .9em
}

.mt1 {
    margin-top: 1rem
}

.mt0 {
    margin-top: .5rem
}

.mb1 {
    margin-bottom: 1rem
}

.mt2 {
    margin-top: 2rem
}

.pt1 {
    padding-top: 1rem
}

.pt2 {
    padding-top: 1.7rem
}

.pt3 {
    padding-top: 3rem
}

#datepicker {
    background-color: var(--prussian-blue);
    color: #fff;
    border-radius: 5px;
    border: none;
    font-weight: 700;
    background-image: url(../../assets/img/elements/_ionicons_svg_md-calendar.svg);
    background-repeat: no-repeat;
    background-position: 90% 50%
}

.add-acc {
    background-color: var(--golden) !important;
    color: var(--white) !important;
    font-size: 1.1em !important;
    background-image: url(../../assets/img/elements/_ionicons_svg_md-add-circle-white.svg);
    background-repeat: no-repeat;
    background-position: 90% 50%;
    border: none
}

.search-acc {
    background-color: var(--golden) !important;
    color: var(--white) !important;
    font-size: 1.1em !important;
    border: none
}

.date-label {
    font-size: 1.2em
}

.date-label i {
    padding-right: 1rem
}

.pb1 {
    padding-bottom: 1rem !important
}

.pb2 {
    padding-bottom: 2rem !important
}

.pb0 {
    padding-bottom: .5rem !important
}

.files input {
    outline-offset: -10px;
    -webkit-transition: outline-offset .15s ease-in-out, background-color .15s linear;
    transition: outline-offset .15s ease-in-out, background-color .15s linear;
    padding: 70px 0 55px 19%;
    text-align: center !important;
    margin: 0;
    width: 100% !important;
    border-radius: 5px;
    box-shadow: 0 1.5px 3px 0 rgba(0, 0, 0, .16);
    background-color: var(--white);
    color: #fff
}

.files input:focus {
    outline: 2px dashed #92b0b3;
    outline-offset: -10px;
    -webkit-transition: outline-offset .15s ease-in-out, background-color .15s linear;
    transition: outline-offset .15s ease-in-out, background-color .15s linear;
    border: 1px solid #92b0b3
}

.files {
    position: relative
}

.files:after {
    pointer-events: none;
    position: absolute;
    top: 40px;
    left: 0;
    width: 30px;
    right: 0;
    height: 36px;
    content: "";
    background-image: url(../../assets/img/elements/cloud-upload.svg);
    display: block;
    margin: 0 auto;
    background-size: 100%;
    background-repeat: no-repeat
}

.color input {
    background-color: #f1f1f1
}

.files:before {
    position: absolute;
    bottom: 10px;
    left: 0;
    pointer-events: none;
    width: 100%;
    right: 0;
    height: 57px;
    content: "";
    display: block;
    margin: 0 auto;
    color: #2ea591;
    font-weight: 600;
    text-transform: capitalize;
    text-align: center
}

.page-intros {
    font-size: 14px;
    line-height: 2;
    letter-spacing: normal;
    text-align: left;
    color: var(--greyish)
}

.for-notifications {
    background: #1787e0;
    background: linear-gradient(90deg, rgba(23, 135, 224, .19371498599439774) 0, rgba(23, 135, 224, .1881127450980392) 12%, rgba(255, 255, 255, 1) 12%, rgba(255, 255, 255, 1) 100%);
    min-height: 700px;
    overflow: hidden;
    overflow-y: scroll
}

.not-i i {
    padding-right: 15px
}

.notifpanes {
    border-radius: 5px;
    border-top-right-radius: 5px;
    margin-right: 0 !important;
    margin-left: 0 !important;
    box-shadow: 0 1.5px 3px 0 rgba(0, 0, 0, .16);
    background: var(--white)
}

.notf-for-pay {
    border-left: 3px solid var(--water-blue)
}

.notf-for-bills {
    border-left: 3px solid var(--prussian-blue)
}

.notf-for-others {
    border-left: 3px solid var(--golden)
}

.breakers {
    height: 2rem;
    background: url(../../assets/img/elements/even.svg) no-repeat 8% 50%;
}

.breakers:last-child {
    height: 2rem;
    background: url(../../assets/img/elements/odd.svg) no-repeat 8% 50%;
}

.notf-date {
    color: var(--greyish);
    font-size: 13px
}

.filter-btns {
    color: var(--water-blue);
    background: var(--white);
    text-transform: capitalize;
    padding: 8px 16px 5px 16px;
    border-radius: 3px;
    border: solid 1px var(--golden);
    display: inline-block;
    display: inline-block;
    -webkit-transition: all .3s ease 0s;
    -moz-transition: all .3s ease 0s;
    -o-transition: all .3s ease 0s;
    transition: all .3s ease 0s
}

.filter-btns-pay {
    color: var(--white);
    background: var(--water-blue);
    text-transform: capitalize;
    padding: 8px 16px 5px 16px;
    border-radius: 3px;
    display: inline-block;
    display: inline-block;
    -webkit-transition: all .3s ease 0s;
    -moz-transition: all .3s ease 0s;
    -o-transition: all .3s ease 0s;
    transition: all .3s ease 0s;
    font-weight: 700
}

.filter-btns-others {
    color: var(--white);
    background-color: var(--golden);
    text-transform: capitalize;
    padding: 8px 16px 5px 16px;
    border-radius: 3px;
    display: inline-block;
    display: inline-block;
    -webkit-transition: all .3s ease 0s;
    -moz-transition: all .3s ease 0s;
    -o-transition: all .3s ease 0s;
    transition: all .3s ease 0s;
    font-weight: 700
}

.filter-btns-processing {
    background-color: #3f3e3e !important;
}

.filter-btns-bills {
    color: var(--white);
    background: var(--prussian-blue);
    text-transform: capitalize;
    padding: 8px 16px 5px 16px;
    border-radius: 3px;
    display: inline-block;
    display: inline-block;
    -webkit-transition: all .3s ease 0s;
    -moz-transition: all .3s ease 0s;
    -o-transition: all .3s ease 0s;
    transition: all .3s ease 0s;
    font-weight: 700
}

.card-img-top {
    margin: auto
}

.main-cards {
    box-shadow: 1.5px 2px 5.5px 0 rgba(23, 135, 224, .5);
    border: none;
    margin: auto;
    width: 8rem;
}

.main-cards img {
    height: 80px;
   padding-top: 10px;
}

.main-cards .services {
   padding: 15px 25px;
}

.acs-height .form-control {
    color: #fff;
    background: 0 0
}

.acs-height .form-control::placeholder {
    color: var(--greyish);
    opacity: 1
}

.acs-height .form-control:-ms-input-placeholder {
    color: var(--greyish)
}

.acs-height .form-control::-ms-input-placeholder {
    color: var(--greyish)
}

.acs-blue {
    background: var(--water-blue)
}

.acs-height {
    height: 100vh;
    padding-top: 10%
}

.signup-height {
    height: 100vh;
    padding-top: 5%
}

.card-plain {
    background: 0 0;
    box-shadow: none;
    border: none
}

.regstr1 {
    margin-left: 0
}

.regstr {
    margin-left: 2rem
}

.card-header-light {
    background: #fff;
    box-shadow: 1.5px 2px 5.5px 0 rgba(23, 135, 224, .5)
}

.tabs-place {
    border-bottom: none
}

.acs-height a,
.acs-height h4,
.acs-height p {
    color: var(--white);
    text-transform: capitalize
}

.acs-link2 a {
    text-transform: none !important;
    color: var(--white)
}

.acs-link2 a i {
    text-transform: none !important;
    color: var(--golden)
}

.acs-height3 {
    height: 100vh;
    padding-top: 10%;
}

.acs-height4 {
    height: 100vh;
    padding-top: 17%;
}

.acs-heigh3t h4,
.acs-height3 p {
    color: var(--charcoal)
}

.acs-link2 a {
    text-transform: capitalize;
    color: var(--white)
}

.acs-link2 a i {
    text-transform: capitalize;
    color: var(--golden)
}

.state-empty p {
    color: var(--greyish)
}

.state-empty img {
    width: 100%
}

.main-cards p {
    color: var(--charcoal);
    border: none;
    text-transform: capitalize;
    font-family: Proxima Nova;
    font-weight: 700;
    line-height: 1.2;
    font-size: 14px
}

.landing-home {
    padding-top: 5%
}

.modal-content {
    padding: 2em
}

.mod-message p {
    font-size: 1.2em
}

.modal-content .nav-link-shy {
    padding: 8px 10px 5px 10px;
    font-size: 14px;
    text-transform: capitalize
}

.modal-content .nav-link-def {
    padding: 8px 40px 5px 40px;
    font-size: 14px;
    text-transform: capitalize
}

.for-payments {
    padding-left: 3rem
}

.type_msg {
    border-top: 1px solid #c4c4c4;
    position: relative
}

.msg_send_btn {
    background: #05728f none repeat scroll 0 0;
    border: medium none;
    border-radius: 50%;
    color: #fff;
    cursor: pointer;
    font-size: 17px;
    height: 33px;
    position: absolute;
    right: 0;
    top: 11px;
    width: 33px
}

.btn-silver {
    color: var(--charcoal);
    background-color: var(--smoke);
    border-color: var(--silver);
    padding: 0 2rem
}

.notf-img img {
    max-width: 32px;
    max-height: 32px
}

.notf p {
    margin-bottom: 0
}

.notf-approved {
    background: var(--water-blue)
}

.notf-approved .nav-link-shy {
    color: var(--white);
    background: 0 0
}

.notf-approved .nav-link-def {
    color: var(--white);
    background: var(--prussian-blue)
}

.main-methods {
    width: fit-content;
    padding: .8rem 1rem;
    border: 1px solid var(--smoke);
    background: var(--white);
    border-radius: 5px
}

.main-methods:hover {
    width: fit-content;
    padding: .8rem 1.3rem;
    border: 1px solid var(--smoke);
    background: var(--white);
    border-radius: 5px;
    box-shadow: 1.5px 2px 5.5px 0 rgba(23, 135, 224, .5);
    transition: .3s
}

.active>.main-methods {
    box-shadow: 1.5px 2px 5.5px 0 rgba(23, 135, 224, .5);
    padding: .8rem 1.3rem;
    width: fit-content;
    border: none;
    border-radius: 5px
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    background-color: transparent
}

.method-space {
    margin-top: 2.2rem
}

.the-lefts {
    float: left;
    position: relative
}

.form-control {
    display: block;
    width: 100%;
    height: calc(2.25rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: transparent;
    background-clip: padding-box;
    border-bottom: 1px solid var(--silver);
    border-radius: 0;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    border-top: none;
    border-left: none;
    border-right: none
}

.pay-shell img {
    width: 100%
}

.the-forms {
    margin-top: 1.2rem
}

@media(min-width:768px) {
    .h2,
    h2 {
        font-size: 36px
    }
}

.h3,
h3 {
    font-size: 20px
}

.ion-icon {
    font-size: 64px
}

.dash-nav li {
    display: inline-block;
    text-align: center;
    padding: 10px 20px;
    color: var(--charcoal);
    line-height: 1
}

.dash-nav li span {
    font-size: 10px;
    text-transform: uppercase
}

.dash-nav li a {
    text-decoration: none;
    color: var(--charcoal)
}

.dash-nav li i {
    font-size: 2em
}

.acc-avatar {
    width: 26px;
    height: 26px;
    border-radius: 50%;
    overflow: hidden;
    text-align: center;
    margin: auto;
    margin-top: 5px
}

.dropbtn {
    background-color: #3498db;
    color: #fff;
    padding: 16px;
    font-size: 16px;
    border: none;
    cursor: pointer
}

.dropbtn:focus,
.dropbtn:hover {
    background-color: #2980b9
}

.dropdown {
    position: relative;
    display: inline-block
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 160px;
    overflow: auto;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, .2);
    z-index: 1
}

.dropdown-content a {
    color: #000;
    padding: 12px 16px;
    text-decoration: none;
    display: block
}

.dropdown a:hover {
    background-color: #ddd
}

.show {
    display: block
}

.pr-avatar {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    overflow: hidden;
    text-align: center;
    margin: auto
}

.acc-avatar+span {
    margin-top: 6px;
    display: block
}

.acc-holder p {
    margin: 0
}

.acc-avatar img,
.pr-avatar img {
    width: 100%;
    margin: auto
}

.sidebar-sticky {
    padding-top: 2em;
    height: 100vh
}

.lipa-maji:before {
    content: "";
    display: block;
    background: url(../../assets/img/white/lipa_maji.svg) no-repeat;
    width: 25px;
    height: 25px;
    float: left;
    background-size: 18px;
    padding-right: 35px
}

.active.lipa-maji:before {
    content: "";
    display: block;
    background: url(../../assets/img/colored/lipa_maji.svg) no-repeat;
    width: 25px;
    height: 25px;
    float: left;
    background-size: 18px;
    padding-right: 35px
}

.jisomee-meter:before {
    content: "";
    display: block;
    background: url(../../assets/img/white/jisomee_meter.svg) no-repeat;
    width: 25px;
    height: 25px;
    float: left;
    background-size: 22px;
    padding-right: 35px
}

.active.jisomee-meter:before {
    content: "";
    display: block;
    background: url(../../assets/img/colored/jisomee_meter.svg) no-repeat;
    width: 25px;
    height: 25px;
    float: left;
    background-size: 22px;
    padding-right: 35px
}

.water-token:before {
    content: "";
    display: block;
    background: url(../../assets/img/white/buy_water_tokens.svg) no-repeat;
    width: 25px;
    height: 25px;
    float: left;
    background-size: 22px;
    padding-right: 35px
}

.active.water-token:before {
    content: "";
    display: block;
    background: url(../../assets/img/colored/buy_water_tokens.svg) no-repeat;
    width: 25px;
    height: 25px;
    float: left;
    background-size: 22px;
    padding-right: 35px
}

.maji-loan:before {
    content: "";
    display: block;
    background: url(../../assets/img/white/bill_statement.svg) no-repeat;
    width: 25px;
    height: 25px;
    float: left;
    background-size: 18px;
    padding-right: 35px
}
.active.maji-loan:before {
    content: "";
    display: block;
    background: url(../../assets/img/colored/view_bill.svg) no-repeat;
    width: 25px;
    height: 25px;
    float: left;
    background-size: 18px;
    padding-right: 35px
}

.bowser:before {
    content: "";
    display: block;
    background: url(../../assets/img/white/bowser_services.svg) no-repeat;
    width: 25px;
    height: 25px;
    float: left;
    background-size: 25px;
    margin: 5px 10px 0 0
}

.active.bowser:before {
    content: "";
    display: block;
    background: url(../../assets/img/colored/bowser_services.svg) no-repeat;
    width: 25px;
    height: 25px;
    float: left;
    background-size: 25px;
    margin: 5px 10px 0 0
}

.exhauster:before {
    content: "";
    display: block;
    background: url(../../assets/img/white/exhauster_sercices.svg) no-repeat;
    width: 25px;
    height: 25px;
    float: left;
    background-size: 25px;
    margin: 5px 10px 0 0
}

.active.exhauster:before {
    content: "";
    display: block;
    background: url(../../assets/img/colored/exhauster_services.svg) no-repeat;
    width: 25px;
    height: 25px;
    float: left;
    background-size: 25px;
    margin: 5px 10px 0 0
}

.apply:before {
    content: "";
    display: block;
    background: url(../../assets/img/white/apply_new_connection.svg) no-repeat;
    width: 25px;
    height: 25px;
    float: left;
    background-size: 18px;
    padding-right: 35px
}

.active.apply:before {
    content: "";
    display: block;
    background: url(../../assets/img/colored/apply_new_connection.svg) no-repeat;
    width: 25px;
    height: 25px;
    float: left;
    background-size: 18px;
    padding-right: 35px
}

.bill:before {
    content: "";
    display: block;
    background: url(../../assets/img/white/bill_statement.svg) no-repeat;
    width: 25px;
    height: 25px;
    float: left;
    background-size: 18px;
    padding-right: 35px
}

.active.bill:before {
    content: "";
    display: block;
    background: url(../../assets/img/colored/view_bill.svg) no-repeat;
    width: 25px;
    height: 25px;
    float: left;
    background-size: 18px;
    padding-right: 35px
}

.services:before {
    content: "";
    display: block;
    background: url(../../assets/img/white/other_services.svg) no-repeat;
    width: 25px;
    height: 25px;
    float: left;
    background-size: 22px;
    padding-right: 35px
}

.active.services:before {
    content: "";
    display: block;
    background: url(../../assets/img/colored/other_services.svg) no-repeat;
    width: 25px;
    height: 25px;
    float: left;
    background-size: 22px;
    padding-right: 35px
}

.report:before {
    content: "";
    display: block;
    background: url(../../assets/img/white/report_incident.svg) no-repeat;
    width: 25px;
    height: 25px;
    float: left;
    background-size: 22px;
    padding-right: 35px
}

.active.report:before {
    content: "";
    display: block;
    background: url(../../assets/img/colored/report_incident.svg) no-repeat;
    width: 25px;
    height: 25px;
    float: left;
    background-size: 22px;
    padding-right: 35px
}

.usage:before {
    content: "";
    display: block;
    background: url(../../assets/img/white/water_usage.svg) no-repeat;
    width: 25px;
    height: 25px;
    float: left;
    background-size: 18px;
    padding-right: 35px
}

.active.usage:before {
    content: "";
    display: block;
    background: url(../../assets/img/colored/water_usage.svg) no-repeat;
    width: 25px;
    height: 25px;
    float: left;
    background-size: 18px;
    padding-right: 35px
}

.nav-link {
    padding: .5rem .5rem !important;
    margin: .5rem 0
}

.nav-item .active {
    background: var(--white);
    color: var(--water-blue);
    position: relative;
    margin-right: -15px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px
}

@supports ((-webkit-backdrop-filter:none) or (backdrop-filter:none)) {
    .navbar-dark {
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);
        background-color: rgba(255, 255, 255, .5)
    }
}

.extra-nav {
    margin-top: 5rem
}

.extra-nav .nav-link {
    padding: .5rem 1rem
}

.sidebar-sticky a {
    color: #fff;
    font-size: 14px;
    text-transform: capitalize
}

.navbar-dark {
    border-bottom: 1px solid var(--silver)
}

.serv-call-to-action {
    font-weight: 500
}

.primary-text {
    color: var(--water-blue)
}

.bg-gray {
    background-color: #f8f8ff
}

.pb-90px {
    padding-bottom: 60px
}

@media(min-width:992px) {
    .pb-90px {
        padding-bottom: 90px
    }
}

.mh-tour {
    max-height: 630px
}

.section-margin {
    margin: 65px 0
}

@media(min-width:992px) {
    .section-margin {
        margin: 60px 0
    }
}

.section-margin-large {
    margin: 65px 0
}

@media(min-width:992px) {
    .section-margin-large {
        margin: 140px 0
    }
}

.section-padding {
    padding: 65px 0
}

@media(min-width:992px) {
    .section-padding {
        padding: 0 0 130px 0
    }
}

.generic-margin {
    margin-top: 100px
}

@media(min-width:992px) {
    .generic-margin {
        margin-top: 180px
    }
}

@media(min-width:1200px) {
    .generic-margin {
        margin-top: 50px
    }
}

.header_area {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 99;
    transition: background .4s, all .3s linear
}

.header_area .navbar-right {
    min-width: 200px;
    text-align: right
}

@media(max-width:991px) {
    .header_area .navbar-right {
        margin: 20px 0
    }
}

.header_area .navbar-brand {
    margin-right: 30px;
    padding: 0
}

.header_area .navbar-social {
    min-width: 95px;
    float: right
}

.header_area .navbar-social li {
    margin-right: 12px
}

.header_area .navbar-social li i,
.header_area .navbar-social li span {
    font-size: 13px
}

.header_area .navbar-social li:last-child {
    margin-right: 0
}

.header_area .navbar-social li a:hover i,
.header_area .navbar-social li span {
    color: var(--water-blue)
}

.header_area .menu_nav {
    width: 100%
}

.header_area .navbar {
    background: 0 0;
    border: 0;
    border-radius: 0;
    width: 100%;
    padding-top: 30px;
    padding-bottom: 0
}

.header_area .navbar .nav-right {
    min-width: 260px
}

.header_area .navbar .nav .nav-item {
    margin-right: 35px
}

.header_area .navbar .nav .nav-item .nav-link {
    color: var(--water-blue);
    text-transform: uppercase;
    padding: 5px 16px;
    border-radius: 3px;
    display: inline-block
}

.nav-link-shy {
    color: var(--water-blue);
    background: var(--white);
    text-transform: uppercase;
    padding: 8px 16px 5px 16px;
    border-radius: 3px;
    border: solid 1px var(--golden);
    display: inline-block;
    display: inline-block;
    -webkit-transition: all .3s ease 0s;
    -moz-transition: all .3s ease 0s;
    -o-transition: all .3s ease 0s;
    transition: all .3s ease 0s
}

.nav-link-def {
    color: var(--white);
    background: var(--water-blue);
    text-transform: uppercase;
    padding: 8px 16px 5px 16px;
    border-radius: 3px;
    border: none;
    display: inline-block;
    display: inline-block;
    -webkit-transition: all .3s ease 0s;
    -moz-transition: all .3s ease 0s;
    -o-transition: all .3s ease 0s;
    transition: all .3s ease 0s
}

.nav-link-disable {
    color: var(--white);
    background: var(--greyish);
    text-transform: uppercase;
    padding: 8px 16px 5px 16px;
    border-radius: 3px;
    display: inline-block;
    display: inline-block;
    -webkit-transition: all .3s ease 0s;
    -moz-transition: all .3s ease 0s;
    -o-transition: all .3s ease 0s;
    transition: all .3s ease 0s;
    cursor: wait
}

.header_area .navbar .nav .nav-item .nav-link-shy {
    color: var(--water-blue);
    background: var(--white);
    text-transform: uppercase;
    padding: 8px 16px 5px 16px;
    border-radius: 3px;
    border: solid 1px var(--golden);
    display: inline-block;
    display: inline-block;
    -webkit-transition: all .3s ease 0s;
    -moz-transition: all .3s ease 0s;
    -o-transition: all .3s ease 0s;
    transition: all .3s ease 0s
}

.header_area .navbar .nav .nav-item .nav-link-def {
    color: var(--white);
    background: var(--water-blue);
    text-transform: uppercase;
    padding: 8px 16px 5px 16px;
    border-radius: 3px;
    display: inline-block;
    display: inline-block;
    -webkit-transition: all .3s ease 0s;
    -moz-transition: all .3s ease 0s;
    -o-transition: all .3s ease 0s;
    transition: all .3s ease 0s
}

.header_area .navbar .nav .nav-item .nav-link:after {
    display: none
}

.header_area .navbar .nav .nav-item.active .nav-link,
.header_area .navbar .nav .nav-item:hover .nav-link {
    color: var(--water-blue);
    opacity: 1
}

.header_area .navbar .nav .nav-item.submenu {
    position: relative
}

.header_area .navbar .nav .nav-item.submenu ul {
    border: none;
    padding: 0;
    border-radius: 0;
    box-shadow: none;
    margin: 0;
    background: #fff
}

@media(min-width:992px) {
    .header_area .navbar .nav .nav-item.submenu ul {
        position: absolute;
        top: 120%;
        left: 0;
        min-width: 200px;
        text-align: left;
        opacity: 0;
        transition: all .3s ease-in;
        visibility: hidden;
        display: block;
        border: none;
        padding: 0;
        border-radius: 0;
        box-shadow: 0 10px 30px 0 rgba(0, 0, 0, .4)
    }
}

.header_area .navbar .nav .nav-item.submenu ul:before {
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 10px 0;
    border-color: #eee transparent transparent transparent;
    position: absolute;
    right: 24px;
    top: 45px;
    z-index: 3;
    opacity: 0;
    transition: all .4s linear
}

.header_area .navbar .nav .nav-item.submenu ul .nav-item {
    display: block;
    float: none;
    margin-right: 0;
    border-bottom: 1px solid #ededed;
    margin-left: 0;
    transition: all .4s linear
}

.header_area .navbar .nav .nav-item.submenu ul .nav-item .nav-link {
    line-height: 45px;
    color: var(--water-blue);
    transition: all 150ms linear;
    display: block;
    margin-right: 0
}

.header_area .navbar .nav .nav-item.submenu ul .nav-item:last-child {
    border-bottom: none
}

.header_area .navbar .nav .nav-item.submenu ul .nav-item:hover .nav-link {
    background-color: var(--water-blue);
    color: #fff !important
}

@media(min-width:992px) {
    .header_area .navbar .nav .nav-item.submenu:hover ul {
        visibility: visible;
        opacity: 1;
        top: 100%
    }
}

.header_area .navbar .nav .nav-item.submenu:hover ul .nav-item {
    margin-top: 0
}

.header_area .navbar .nav .nav-item:last-child {
    margin-right: 0
}

.header_area .navbar .search {
    font-size: 12px;
    line-height: 60px;
    display: inline-block;
    margin-left: 80px
}

.header_area .navbar .search i {
    font-weight: 600
}

.header_area.navbar_fixed .main_menu {
    position: fixed;
    width: 100%;
    top: -70px;
    left: 0;
    right: 0;
    z-index: 99;
    transform: translateY(70px);
    transition: transform .5s ease, background .5s ease;
    -webkit-transition: transform .5s ease, background .5s ease;
    box-shadow: 0 3px 16px 0 rgba(0, 0, 0, .1)
}

.header_area.navbar_fixed .main_menu .navbar {
    background: #7676ff
}

.header_area.navbar_fixed .main_menu .navbar .nav .nav-item .nav-link {
    line-height: 20px
}

.header_area.navbar_fixed .main_menu .navbar .nav .nav-item.active .nav-link,
.header_area.navbar_fixed .main_menu .navbar .nav .nav-item:hover .nav-link {
    color: #fff
}

@media(min-width:992px) {
    .header_area.white_menu .navbar .navbar-brand img {
        display: none
    }
    .header_area.white_menu .navbar .navbar-brand img+img {
        display: inline-block
    }
}

@media(max-width:991px) {
    .header_area.white_menu .navbar .navbar-brand img {
        display: inline-block
    }
    .header_area.white_menu .navbar .navbar-brand img+img {
        display: none
    }
}

.header_area.white_menu .navbar .nav .nav-item .nav-link {
    color: #fff
}

.header_area.white_menu.navbar_fixed .main_menu .navbar .navbar-brand img {
    display: inline-block
}

.header_area.white_menu.navbar_fixed .main_menu .navbar .navbar-brand img+img {
    display: none
}

.header_area.white_menu.navbar_fixed .main_menu .navbar .nav .nav-item .nav-link {
    line-height: 70px
}

.top_menu {
    background: #ec9d5f
}

.top_menu .float-left a {
    line-height: 40px;
    display: inline-block;
    color: #fff;
    text-transform: uppercase;
    font-size: 12px;
    margin-right: 50px;
    -webkit-transition: all .3s ease 0s;
    -moz-transition: all .3s ease 0s;
    -o-transition: all .3s ease 0s;
    transition: all .3s ease 0s
}

.top_menu .float-left a:last-child {
    margin-right: 0
}

.top_menu .float-right .pur_btn {
    color: #fff;
    line-height: 40px;
    display: block;
    padding: 0 40px;
    font-weight: 500;
    font-size: 12px
}

@media(max-width:1619px) {
    .header_area .navbar .search {
        margin-left: 40px
    }
}

@media(max-width:1199px) {
    .header_area .navbar .nav .nav-item {
        margin-right: 28px
    }
    .home_banner_area .banner_inner {
        padding: 100px 0
    }
    .header_area .navbar .tickets_btn {
        margin-left: 40px
    }
    .home_banner_area .banner_inner .banner_content h2 br {
        display: none;
        font-size: 36px
    }
    .home_banner_area .banner_inner .banner_content h2 {
        font-size: 36px;
        line-height: 45px
    }
    .magic-ball {
        position: relative;
        height: 100% !important
    }
}

@media(max-width:991px) {
    .navbar-toggler {
        border: none;
        border-radius: 0;
        padding: 0;
        cursor: pointer;
        margin-top: 27px;
        margin-bottom: 23px
    }
    .header_area .navbar {
        background: #d1cff4
    }
    .header_area .navbar .nav-item.active .nav-link {
        color: var(--water-blue)
    }
    .navbar-toggler[aria-expanded=true] span:nth-child(2) {
        opacity: 0
    }
    .navbar-toggler[aria-expanded=true] span:first-child {
        transform: rotate(-45deg);
        position: relative;
        top: 7.5px
    }
    .navbar-toggler[aria-expanded=true] span:last-child {
        transform: rotate(45deg);
        bottom: 6px;
        position: relative
    }
    .navbar-toggler span {
        display: block;
        width: 25px;
        height: 3px;
        background: var(--water-blue);
        margin: auto;
        margin-bottom: 4px;
        transition: all .4s linear;
        cursor: pointer
    }
    .navbar .container {
        padding-left: 15px;
        padding-right: 15px
    }
    .nav {
        padding: 0
    }
    .header_top .nav {
        padding: 0
    }
    .header_area .navbar .nav .nav-item .nav-link {
        padding: 12px 0;
        margin-right: 0;
        display: block;
        border-bottom: 1px solid #ededed33;
        border-radius: 0
    }
    .header_area .navbar .search {
        margin-left: 0
    }
    .header_area .navbar-collapse {
        max-height: 340px;
        overflow-y: scroll
    }
    .header_area .navbar .nav .nav-item.submenu ul .nav-item .nav-link {
        padding: 0 15px
    }
    .header_area .navbar .nav .nav-item {
        margin-right: 0
    }
    .home_banner_area .banner_inner .banner_content .banner_map_img {
        display: none
    }
    .header_area.navbar_fixed .main_menu .navbar .nav .nav-item .nav-link {
        line-height: 40px
    }
    .header_area.white_menu.navbar_fixed .main_menu .navbar .nav .nav-item .nav-link {
        line-height: 40px
    }
    .header_area.white_menu .navbar .nav .nav-item .nav-link {
        color: #222
    }
    .categories_post img {
        width: 100%
    }
    .categories_post {
        max-width: 360px;
        margin: 0 auto
    }
    .blog_categorie_area .col-lg-4 {
        margin-top: 30px
    }
    .blog_area {
        padding-bottom: 80px
    }
    .single-post-area .blog_right_sidebar {
        margin-top: 30px
    }
    .contact_info {
        margin-bottom: 50px
    }
    .home_banner_area .donation_inner {
        margin-bottom: -30px
    }
    .home_banner_area .dontation_item {
        max-width: 350px;
        margin: auto
    }
    .footer_area .col-sm-6 {
        margin-bottom: 30px
    }
    .footer_area .footer_inner {
        margin-bottom: -30px
    }
    .news_widget {
        padding-left: 0
    }
    .home_banner_area .banner_inner .home_left_img {
        display: none
    }
    .header_area .navbar .tickets_btn {
        display: none
    }
    .home_banner_area .banner_inner {
        padding: 70px 0 100px
    }
    .left_side_text {
        margin-bottom: 50px
    }
    .price_item {
        max-width: 360px;
        margin: 0 auto 30px
    }
    .price_inner {
        margin-bottom: -30px
    }
}

@media(max-width:767px) {
    .home_banner_area {
        min-height: 500px
    }
    .home_banner_area .banner_inner {
        min-height: 500px
    }
    .home_banner_area .banner_inner .banner_content {
        margin-top: 0
    }
    .blog_banner .banner_inner .blog_b_text h2 {
        font-size: 40px;
        line-height: 50px
    }
    .blog_info.text-right {
        text-align: left !important;
        margin-bottom: 10px
    }
    .home_banner_area .banner_inner .banner_content h3 {
        font-size: 30px
    }
    .home_banner_area .banner_inner .banner_content p br {
        display: none
    }
    .home_banner_area .banner_inner .banner_content h3 span {
        line-height: 45px;
        padding-bottom: 0;
        padding-top: 0
    }
    .footer-bottom {
        text-align: center
    }
    .footer-bottom .footer-social {
        text-align: center;
        margin-top: 15px
    }
    .made_life_inner .nav.nav-tabs li {
        flex: 0 0 50%;
        max-width: 50%;
        margin-bottom: 15px
    }
    .made_life_inner .nav.nav-tabs {
        margin-bottom: -15px
    }
    .made_life_area.made_white .left_side_text {
        margin-bottom: 0;
        margin-top: 30px
    }
    .main-servs {
        margin-top: 1rem;
    }
    /* .main-servs .main-cards {
        min-height: 159.59px !important;
    } */
    .r-mt2 {
        margin-top: 0
    }
    .r-mt-xs {
        margin-top: 1em
    }
    .xs-mt1 {
        margin-top: .5rem
    }
    .main-window {
        margin-bottom: 6em
    }
    .header_area .navbar-collapse {
        max-height: 250px;
        margin-bottom: 1em
    }
    .suspend-hdr {
        position: fixed;
        bottom: 0;
        backdrop-filter: blur(10px);
        background-color: rgba(255, 255, 255, .9);
        width: 100%;
        margin-left: 0
    }
    .suspend-hdr .dash-nav {
        justify-content: center !important
    }
    .sticky-top .logo-xs a img {
        margin-top: 2rem
    }
    .logo-xs {
        margin: .5rem 15px;
        text-align: center;
        background-color: rgba(255, 255, 255, .9);
        backdrop-filter: blur(10px)
    }
    .logo-xs img {
        max-width: 50px;
        margin: auto
    }
    /* .logo_h img {
        width: 32% !important;
    } */

    /* .maji_connect_logo {
        width: 32% !important;
    } */

    .dash-nav li {
        display: inline-block;
        text-align: center;
        padding: 10px 15px;
        color: var(--charcoal);
        line-height: 1
    }
    .acs-height3 {
        height: 100vh;
        padding-top: 10%;
        margin-top: 4em
    }
    .guttrs-xs {
        margin-left: 15px;
        margin-right: 15px
    }
    .in-guttrs-xs {
        margin-left: -15px;
        margin-right: -15px
    }
    .acs-height {
        height: 100%;
        padding-top: 10%
    }
    .navbar-toggler {
        border: none;
        border-radius: 0;
        padding: 0;
        cursor: pointer;
        margin-top: 7px;
        margin-bottom: 23px
    }
}

@media(max-width:575px) {
    .maji_connect_logo {
        width: 70% !important;
    }
    .top_menu {
        display: none
    }
    .home_banner_area .banner_inner .banner_content h2 {
        font-size: 28px;
        line-height: 38px
    }
    .home_banner_area {
        min-height: 450px
    }
    .home_banner_area .banner_inner {
        min-height: 450px
    }
    .blog_banner .banner_inner .blog_b_text {
        margin-top: 0
    }
    .home_banner_area .banner_inner .banner_content img {
        display: none
    }
    .home_banner_area .banner_inner .banner_content h5 {
        margin-top: 0
    }
    .p_120 {
        padding-top: 70px;
        padding-bottom: 70px
    }
    .main_title h2 {
        font-size: 25px
    }
    .sample-text-area {
        padding: 70px 0
    }
    .generic-blockquote {
        padding: 30px 15px 30px 30px
    }
    .blog_details h2 {
        font-size: 20px;
        line-height: 30px
    }
    .blog_banner .banner_inner .blog_b_text h2 {
        font-size: 28px;
        line-height: 38px
    }
    .footer-area {
        padding: 70px 0
    }
    .pad_top {
        padding-top: 70px
    }
    .pad_btm {
        padding-bottom: 70px
    }
}

@media(max-width:480px) {
    .header_area .navbar-collapse {
        max-height: 250px
    }
    .home_banner_area .banner_inner .banner_content {
        padding: 30px 15px;
        margin-top: 0
    }
    .banner_content .white_btn {
        display: block
    }
    .home_banner_area .banner_inner .banner_content h3 {
        font-size: 24px
    }
    .banner_area .banner_inner .banner_content h2 {
        font-size: 32px
    }
    .comments-area .thumb {
        margin-right: 10px
    }
    .testi_item .media .d-flex {
        padding-right: 0
    }
    .testi_item .media .d-flex img {
        width: auto;
        margin-bottom: 15px
    }
    .testi_item .media {
        display: block
    }
    .banner_btn {
        margin-bottom: 20px
    }
}

.hero-banner {
    padding-top: 120px;
    padding-bottom:10em;
    background: url(../../assets/img/bgh.svg) no-repeat;
    background-size: cover;
}

@media(min-width:992px) {
    .hero-banner {
        padding-top: 160px;
        padding-bottom: 5em;
        background: url(../../assets/img/bgh.svg) no-repeat;
        background-size: cover;
    }
}

.hero-banner h1 {
    text-transform: uppercase;
    color: var(--water-blue);
    margin-bottom: 15px
}

@media(max-width:1200px) {
    .hero-banner h1 {
        font-size: 37px
    }
}

@media(max-width:991px) {
    .hero-banner h1 {
        font-size: 30px
    }
}

.hero-banner-sm {
    background-size: cover;
    padding-top: 140px;
    padding-bottom: 90px
}

@media(min-width:992px) {
    .hero-banner-sm {
        padding-top: 240px;
        padding-bottom: 140px
    }
}

.hero-banner-sm h1 {
    text-transform: uppercase;
    color: var(--water-blue)
}

@media(max-width:991px) {
    .hero-banner-sm h1 {
        font-size: 38px
    }
}

@media(max-width:768px) {
    .hero-banner-sm h1 {
        font-size: 28px
    }
}

.hero-banner-sm p {
    margin-bottom: 0
}

.hero-banner-sm-content {
    max-width: 400px
}

.bg-shape {
    position: relative
}

.banner-breadcrumb {
    display: inline-block
}

.banner-breadcrumb .breadcrumb {
    background: 0 0;
    padding: 0
}

.banner-breadcrumb .breadcrumb-item {
    padding: .1rem
}

.banner-breadcrumb .breadcrumb-item a {
    text-transform: capitalize;
    color: #fff
}

.banner-breadcrumb .breadcrumb-item+.breadcrumb-item::before {
    color: #fff;
    padding-left: .2rem;
    padding-right: .4rem
}

.banner-breadcrumb .breadcrumb-item.active {
    color: #fff
}

.section-intro {
    max-width: 640px;
    margin-right: auto;
    margin-left: auto
}

.section-intro h2 {
    color: var(--water-blue);
    font-family: Proxima Nova;
    font-weight: 700;
    font-size: 1.6em
}

.section-intro p {
    margin-bottom: 0
}

.section-intro-img {
    margin-bottom: 10px
}

.service-card {
    background: var(--white);
    margin-top: 2em;
    -webkit-transition: all .3s ease 0s;
    -moz-transition: all .3s ease 0s;
    -o-transition: all .3s ease 0s;
    transition: all .3s ease 0s;
    box-shadow: 1.5px 2px 5.5px 0 rgba(23, 135, 224, .5);
    border-radius: 5px
}

.service-card:hover {
    background: #fff
}

.service-card-img {
    padding-top: 25px;
}

.service-card-img img {
    padding: 10px 30px 10px;
    max-height: 152px;
    width: 100%;
    
}

@media(max-width:600px) {
    .service-card-img {
    }
}

.service-card-body {
    text-align: center;
    padding: 10px 30px 10px
}

.service-card-body h3 {
    margin-bottom: 8px
}

@media(min-width:768px) {
    .service-card-body h3 {
        margin-bottom: 15px
    }
}

.about-content h2 {
    margin-bottom: 15px
}

@media(min-width:768px) {
    .about-content h2 {
        margin-bottom: 25px
    }
}

.about-content p {
    margin-bottom: 20px
}

@media(min-width:768px) {
    .about-content p {
        margin-bottom: 40px
    }
}

@media(min-width:1200px) {
    .about-img {
        position: relative
    }
}

.tour-card {
    position: relative;
    margin-bottom: 30px
}

.tour-card .tour-card-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #fff;
    padding: 40px;
    opacity: 0;
    z-index: -1;
    transform: translateY(40px);
    -webkit-transition: all .3s ease 0s;
    -moz-transition: all .3s ease 0s;
    -o-transition: all .3s ease 0s;
    transition: all .3s ease 0s
}

.tour-card .tour-card-overlay h4 {
    margin-bottom: 0
}

.tour-card .tour-card-overlay small {
    font-size: 15px;
    color: #555;
    margin-bottom: 10px
}

.tour-card .tour-card-overlay p {
    font-size: 16px
}

.tour-card:hover .tour-card-overlay {
    transform: translateY(0);
    width: 100%;
    opacity: 1;
    z-index: 1
}

@media(min-width:1200px) {
    .tour-card:hover .tour-card-overlay {
        width: 74%
    }
}

.tour-content {
    padding: 8px 20px 25px 15px
}

.tour-content h2 {
    margin-bottom: 15px
}

.magic-ball {
    position: relative;
    height: 100%
}

.testimonial {
    max-width: 950px;
    margin-right: auto;
    margin-left: auto
}

.testimonial__item {
    background: #fff;
    border-radius: 20px;
    padding: 30px 15px
}

@media(min-width:768px) {
    .testimonial__item {
        padding: 30px 50px
    }
}

.testimonial__img {
    max-width: 132px
}

@media(min-width:1200px) {
    .testimonial__content {
        padding-left: 22px
    }
}

.testimonial__content h3 {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 0
}

.testimonial__content p {
    font-size: 14px;
    margin-bottom: 10px
}

.testimonial__i {
    font-size: 15px !important;
    font-style: italic;
    margin-bottom: 0 !important
}

.testimonial__icon {
    font-size: 173px;
    color: rgba(119, 119, 119, .1);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 40px
}

.testimonial .owl-dots {
    margin-top: 30px !important
}

@media(min-width:600px) {
    .testimonial .owl-dots {
        margin-top: 60px !important
    }
}

.testimonial .owl-dots .owl-dot span {
    width: 20px;
    height: 4px;
    background: #cdcad5;
    border-radius: 5px;
    margin: 5px 8px
}

.testimonial .owl-dots .owl-dot.active span {
    height: 6px;
    background: var(--water-blue)
}

.card-blog {
    background: #fff
}

.card-blog .card-blog-body {
    padding: 20px 10px;
    -webkit-transition: all .3s ease 0s;
    -moz-transition: all .3s ease 0s;
    -o-transition: all .3s ease 0s;
    transition: all .3s ease 0s
}

@media(min-width:1200px) {
    .card-blog .card-blog-body {
        padding: 25px 25px 30px 40px
    }
}

.card-blog .card-blog-body h4 {
    font-size: 20px;
    margin-bottom: 15px
}

.card-blog .card-blog-body p {
    margin-bottom: 0
}

.card-blog:hover .card-blog-body {
    box-shadow: 0 10px 20px 0 rgba(14, 30, 112, .1)
}

.card-blog-info {
    margin-bottom: 15px
}

.card-blog-info li {
    display: inline-block;
    font-size: 14px;
    color: #888;
    margin-right: 25px
}

.card-blog-info li:last-child {
    margin-right: 0
}

.card-blog-info li a {
    color: #888
}

.card-blog-info li i,
.card-blog-info li span {
    margin-right: 5px
}

.search-wrapper {
    background: #f8f8ff;
    padding: 30px 10px;
    text-align: center
}

@media(min-width:600px) {
    .search-wrapper {
        padding: 60px
    }
}

.search-wrapper h3 {
    font-size: 25px;
    margin-bottom: 25px
}

@media(min-width:600px) {
    .search-wrapper h3 {
        margin-bottom: 50px
    }
}

.search-form .form-group {
    margin-bottom: 20px
}

.search-form .nice-select {
    width: 100%;
    height: 50px;
    line-height: 50px;
    border: 0;
    border-radius: 0;
    margin-bottom: 20px;
    font-size: 14px;
    color: #999
}

.search-form .nice-select:after {
    right: 25px
}

.search-form .form-control {
    font-size: 14px;
    color: #999;
    padding-left: 20px;
    border: 0;
    height: 50px;
    border-radius: 0
}

.search-form .form-control::placeholder {
    color: #999
}

.search-form .form-control:focus {
    outline: 0;
    box-shadow: none
}

.search-form .input-group-append .input-group-text {
    border: 0;
    padding-right: 20px;
    border-radius: 0;
    background: #fff
}

.search-form .input-group-append i,
.search-form .input-group-append span {
    font-size: 14px;
    color: #999
}

.search-form::placeholder {
    color: #999
}

.payment img {
    max-width: 80%;
    margin: auto
}

.search-content h2 {
    margin-bottom: 10px;
    color: var(--water-blue);
    font-family: Proxima Nova;
    font-weight: 700
}

@media(min-width:768px) {
    .search-content h2 {
        margin-bottom: 20px
    }
}

.search-content p {
    margin-bottom: 25px
}

@media(min-width:768px) {
    .search-content p {
        margin-bottom: 40px
    }
}

.contact-info {
    margin-bottom: 12px
}

.contact-info__icon {
    margin-right: 20px
}

.contact-info__icon i,
.contact-info__icon span {
    color: #8f9195;
    font-size: 27px
}

.contact-info .media-body h3 {
    font-size: 16px;
    margin-bottom: 0
}

.contact-info .media-body h3 a {
    color: #2a2a2a
}

.contact-info .media-body p {
    color: #999
}

.contact-title {
    font-size: 27px;
    font-weight: 600;
    margin-bottom: 20px
}

.form-contact label {
    color: var(--water-blue);
    font-size: 14px
}

.form-contact .form-group {
    margin-bottom: 30px
}

.form-contact .form-control {
    border: 1px solid #f0e9ff;
    border-radius: 5px;
    height: 48px;
    padding-left: 18px;
    font-size: 13px;
    background: 0 0
}

.form-contact .form-control:focus {
    outline: 0;
    box-shadow: none
}

.form-contact .form-control::placeholder {
    font-weight: 300;
    color: #999
}

.form-contact textarea {
    border-radius: 12px;
    height: 100% !important
}

.l_blog_item .l_blog_text .date {
    margin-top: 24px;
    margin-bottom: 15px
}

.l_blog_item .l_blog_text .date a {
    font-size: 12px
}

.l_blog_item .l_blog_text h4 {
    font-size: 18px;
    color: #2a2a2a;
    border-bottom: 1px solid #eee;
    margin-bottom: 0;
    padding-bottom: 20px;
    -webkit-transition: all .3s ease 0s;
    -moz-transition: all .3s ease 0s;
    -o-transition: all .3s ease 0s;
    transition: all .3s ease 0s
}

.l_blog_item .l_blog_text h4:hover {
    color: var(--water-blue)
}

.l_blog_item .l_blog_text p {
    margin-bottom: 0;
    padding-top: 20px
}

.causes_slider .owl-dots {
    text-align: center;
    margin-top: 80px
}

.causes_slider .owl-dots .owl-dot {
    height: 14px;
    width: 14px;
    background: #eee;
    display: inline-block;
    margin-right: 7px
}

.causes_slider .owl-dots .owl-dot:last-child {
    margin-right: 0
}

.causes_slider .owl-dots .owl-dot.active {
    background: var(--water-blue)
}

.causes_item {
    background: #fff
}

.causes_item .causes_img {
    position: relative
}

.causes_item .causes_img .c_parcent {
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    height: 3px;
    background: rgba(255, 255, 255, .5)
}

.causes_item .causes_img .c_parcent span {
    width: 70%;
    height: 3px;
    background: var(--water-blue);
    position: absolute;
    left: 0;
    bottom: 0
}

.causes_item .causes_img .c_parcent span:before {
    content: "75%";
    position: absolute;
    right: -10px;
    bottom: 0;
    background: var(--water-blue);
    color: #fff;
    padding: 0 5px
}

.causes_item .causes_text {
    padding: 30px 35px 40px 30px
}

.causes_item .causes_text h4 {
    color: #2a2a2a;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 15px;
    cursor: pointer
}

.causes_item .causes_text h4:hover {
    color: var(--water-blue)
}

.causes_item .causes_text p {
    font-size: 14px;
    line-height: 24px;
    font-weight: 300;
    margin-bottom: 0
}

.causes_item .causes_bottom a {
    width: 50%;
    border: 1px solid var(--water-blue);
    text-align: center;
    float: left;
    line-height: 50px;
    background: var(--water-blue);
    color: #fff;
    font-size: 14px;
    font-weight: 500
}

.causes_item .causes_bottom a+a {
    border-color: #eee;
    background: #fff;
    font-size: 14px;
    color: #2a2a2a
}

.latest_blog_area {
    background: #f9f9ff
}

.single-recent-blog-post {
    margin-bottom: 30px
}

.single-recent-blog-post .thumb {
    overflow: hidden
}

.single-recent-blog-post .thumb img {
    transition: all .7s linear
}

.single-recent-blog-post .details {
    padding-top: 30px
}

.single-recent-blog-post .details .sec_h4 {
    line-height: 24px;
    padding: 10px 0 13px;
    transition: all .3s linear
}

.single-recent-blog-post .date {
    font-size: 14px;
    line-height: 24px;
    font-weight: 400
}

.single-recent-blog-post:hover img {
    transform: scale(1.23) rotate(10deg)
}

.tags .tag_btn {
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    border: 1px solid #eee;
    display: inline-block;
    padding: 1px 18px;
    text-align: center;
    color: #2a2a2a
}

.tags .tag_btn:before {
    background: var(--water-blue)
}

.tags .tag_btn+.tag_btn {
    margin-left: 2px
}

.blog_categorie_area {
    padding-top: 30px;
    padding-bottom: 30px
}

@media(min-width:900px) {
    .blog_categorie_area {
        padding-top: 80px;
        padding-bottom: 80px
    }
}

@media(min-width:1100px) {
    .blog_categorie_area {
        padding-top: 120px;
        padding-bottom: 120px
    }
}

.categories_post {
    position: relative;
    text-align: center;
    cursor: pointer
}

.categories_post img {
    max-width: 100%
}

.categories_post .categories_details {
    position: absolute;
    top: 20px;
    left: 20px;
    right: 20px;
    bottom: 20px;
    background: rgba(34, 34, 34, .75);
    color: #fff;
    transition: all .3s linear;
    display: flex;
    align-items: center;
    justify-content: center
}

.categories_post .categories_details h5 {
    margin-bottom: 0;
    font-size: 18px;
    line-height: 26px;
    text-transform: uppercase;
    color: #fff;
    position: relative
}

.categories_post .categories_details p {
    font-weight: 300;
    font-size: 14px;
    line-height: 26px;
    margin-bottom: 0
}

.categories_post .categories_details .border_line {
    margin: 10px 0;
    background: #fff;
    width: 100%;
    height: 1px
}

.categories_post:hover .categories_details {
    background: rgba(222, 99, 32, .85)
}

.blog_item {
    margin-bottom: 50px
}

.blog_details {
    padding: 30px 0 20px 10px;
    box-shadow: 0 10px 20px 0 rgba(221, 221, 221, .3)
}

@media(min-width:768px) {
    .blog_details {
        padding: 60px 30px 35px 35px
    }
}

.blog_details p {
    margin-bottom: 30px
}

.blog_details h2 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 8px
}

@media(min-width:768px) {
    .blog_details h2 {
        font-size: 24px;
        margin-bottom: 15px
    }
}

.blog-info-link li {
    float: left;
    font-size: 14px
}

.blog-info-link li a {
    color: #999
}

.blog-info-link li i,
.blog-info-link li span {
    font-size: 13px;
    margin-right: 5px
}

.blog-info-link li::after {
    content: "|";
    padding-left: 10px;
    padding-right: 10px
}

.blog-info-link li:last-child::after {
    display: none
}

.blog-info-link::after {
    content: "";
    display: block;
    clear: both;
    display: table
}

.blog_item_img {
    position: relative
}

.blog_item_img .blog_item_date {
    position: absolute;
    bottom: -10px;
    left: 10px;
    display: block;
    color: #fff;
    background: var(--water-blue);
    padding: 8px 15px;
    border-radius: 5px
}

@media(min-width:768px) {
    .blog_item_img .blog_item_date {
        bottom: -20px;
        left: 40px;
        padding: 13px 30px
    }
}

.blog_item_img .blog_item_date h3 {
    font-size: 22px;
    font-weight: 600;
    color: #fff;
    margin-bottom: 0;
    line-height: 1.2
}

@media(min-width:768px) {
    .blog_item_img .blog_item_date h3 {
        font-size: 30px
    }
}

.blog_item_img .blog_item_date p {
    margin-bottom: 0
}

@media(min-width:768px) {
    .blog_item_img .blog_item_date p {
        font-size: 18px
    }
}

.blog_right_sidebar .widget_title {
    font-size: 20px;
    margin-bottom: 40px
}

.blog_right_sidebar .widget_title::after {
    content: "";
    display: block;
    padding-top: 15px;
    border-bottom: 1px solid #f0e9ff
}

.blog_right_sidebar .single_sidebar_widget {
    background: #fbf9ff;
    padding: 30px;
    margin-bottom: 30px
}

.blog_right_sidebar .search_widget .form-control {
    height: 50px;
    border-color: #f0e9ff;
    font-size: 13px;
    color: #999;
    padding-left: 20px;
    border-radius: 0;
    border-right: 0
}

.blog_right_sidebar .search_widget .form-control::placeholder {
    color: #999
}

.blog_right_sidebar .search_widget .form-control:focus {
    border-color: #f0e9ff;
    outline: 0;
    box-shadow: none
}

.blog_right_sidebar .search_widget .input-group button {
    background: #fff;
    border-left: 0;
    border: 1px solid #f0e9ff;
    padding: 4px 15px;
    border-left: 0
}

.blog_right_sidebar .search_widget .input-group button i,
.blog_right_sidebar .search_widget .input-group button span {
    font-size: 14px;
    color: #999
}

.blog_right_sidebar .newsletter_widget .form-control {
    height: 50px;
    border-color: #f0e9ff;
    font-size: 13px;
    color: #999;
    padding-left: 20px;
    border-radius: 0
}

.blog_right_sidebar .newsletter_widget .form-control::placeholder {
    color: #999
}

.blog_right_sidebar .newsletter_widget .form-control:focus {
    border-color: #f0e9ff;
    outline: 0;
    box-shadow: none
}

.blog_right_sidebar .newsletter_widget .input-group button {
    background: #fff;
    border-left: 0;
    border: 1px solid #f0e9ff;
    padding: 4px 15px;
    border-left: 0
}

.blog_right_sidebar .newsletter_widget .input-group button i,
.blog_right_sidebar .newsletter_widget .input-group button span {
    font-size: 14px;
    color: #999
}

.blog_right_sidebar .post_category_widget .cat-list li {
    border-bottom: 1px solid #f0e9ff;
    transition: all .3s ease 0s;
    padding-bottom: 12px
}

.blog_right_sidebar .post_category_widget .cat-list li:last-child {
    border-bottom: 0
}

.blog_right_sidebar .post_category_widget .cat-list li a {
    font-size: 14px;
    line-height: 20px;
    color: #888
}

.blog_right_sidebar .post_category_widget .cat-list li a p {
    margin-bottom: 0
}

.blog_right_sidebar .post_category_widget .cat-list li+li {
    padding-top: 15px
}

.blog_right_sidebar .post_category_widget .cat-list li:hover a {
    color: var(--water-blue)
}

.blog_right_sidebar .popular_post_widget .post_item .media-body {
    justify-content: center;
    align-self: center;
    padding-left: 20px
}

.blog_right_sidebar .popular_post_widget .post_item .media-body h3 {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 6px;
    transition: all .3s linear
}

.blog_right_sidebar .popular_post_widget .post_item .media-body h3:hover {
    color: var(--water-blue)
}

.blog_right_sidebar .popular_post_widget .post_item .media-body p {
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 0
}

.blog_right_sidebar .popular_post_widget .post_item+.post_item {
    margin-top: 20px
}

.blog_right_sidebar .tag_cloud_widget ul li {
    display: inline-block
}

.blog_right_sidebar .tag_cloud_widget ul li a {
    display: inline-block;
    border: 1px solid #eee;
    background: #fff;
    padding: 4px 20px;
    margin-bottom: 8px;
    margin-right: 5px;
    transition: all .3s ease 0s;
    color: #888;
    font-size: 13px
}

.blog_right_sidebar .tag_cloud_widget ul li a:hover {
    background: var(--water-blue);
    color: #fff
}

.blog_right_sidebar .instagram_feeds .instagram_row {
    display: flex;
    margin-right: -6px;
    margin-left: -6px
}

.blog_right_sidebar .instagram_feeds .instagram_row li {
    width: 33.33%;
    float: left;
    padding-right: 6px;
    padding-left: 6px;
    margin-bottom: 15px
}

.blog_right_sidebar .br {
    width: 100%;
    height: 1px;
    background: #eee;
    margin: 30px 0
}

.blog-pagination {
    margin-top: 80px
}

.blog-pagination .page-link {
    font-size: 14px;
    position: relative;
    display: block;
    padding: 0;
    text-align: center;
    margin-left: -1px;
    line-height: 45px;
    width: 45px;
    height: 45px;
    border-radius: 0 !important;
    color: #8a8a8a;
    border: 1px solid #f0e9ff;
    margin-right: 10px
}

.blog-pagination .page-link i,
.blog-pagination .page-link span {
    font-size: 13px
}

.blog-pagination .page-link:hover {
    background-color: #fbf9ff
}

.blog-pagination .page-item.active .page-link {
    background-color: #fbf9ff;
    border-color: #f0e9ff;
    color: #888
}

.blog-pagination .page-item:last-child .page-link {
    margin-right: 0
}

.single-post-area .blog_details {
    box-shadow: none;
    padding: 0
}

.single-post-area .social-links {
    padding-top: 10px
}

.single-post-area .social-links li {
    display: inline-block;
    margin-bottom: 10px
}

.single-post-area .social-links li a {
    color: #ccc;
    padding: 7px;
    font-size: 14px;
    transition: all .2s linear
}

.single-post-area .social-links li a:hover {
    color: #2a2a2a
}

.single-post-area .blog_details {
    padding-top: 26px
}

.single-post-area .blog_details p {
    margin-bottom: 20px
}

.single-post-area .quote-wrapper {
    background: rgba(130, 139, 178, .1);
    padding: 15px;
    line-height: 1.733;
    color: #888;
    font-style: italic;
    margin-top: 25px;
    margin-bottom: 25px
}

@media(min-width:768px) {
    .single-post-area .quote-wrapper {
        padding: 30px
    }
}

.single-post-area .quotes {
    background: #fff;
    padding: 15px 15px 15px 20px;
    border-left: 2px solid var(--water-blue)
}

@media(min-width:768px) {
    .single-post-area .quotes {
        padding: 25px 25px 25px 30px
    }
}

.single-post-area .arrow {
    position: absolute
}

.single-post-area .arrow .lnr {
    font-size: 20px;
    font-weight: 600
}

.single-post-area .thumb .overlay-bg {
    background: rgba(0, 0, 0, .8)
}

.single-post-area .navigation-top {
    padding-top: 15px;
    border-top: 1px solid #f0e9ff
}

.single-post-area .navigation-top p {
    margin-bottom: 0
}

.single-post-area .navigation-top .like-info {
    font-size: 14px
}

.single-post-area .navigation-top .like-info i,
.single-post-area .navigation-top .like-info span {
    font-size: 16px;
    margin-right: 5px
}

.single-post-area .navigation-top .comment-count {
    font-size: 14px
}

.single-post-area .navigation-top .comment-count i,
.single-post-area .navigation-top .comment-count span {
    font-size: 16px;
    margin-right: 5px
}

.single-post-area .navigation-top .social-icons li {
    display: inline-block;
    margin-right: 15px
}

.single-post-area .navigation-top .social-icons li:last-child {
    margin: 0
}

.single-post-area .navigation-top .social-icons li i,
.single-post-area .navigation-top .social-icons li span {
    font-size: 14px;
    color: #999
}

.single-post-area .navigation-top .social-icons li:hover i,
.single-post-area .navigation-top .social-icons li:hover span {
    color: var(--water-blue)
}

.single-post-area .blog-author {
    padding: 40px 30px;
    background: #fbf9ff;
    margin-top: 50px
}

@media(max-width:600px) {
    .single-post-area .blog-author {
        padding: 20px 8px
    }
}

.single-post-area .blog-author img {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    margin-right: 30px
}

@media(max-width:600px) {
    .single-post-area .blog-author img {
        margin-right: 15px;
        width: 45px;
        height: 45px
    }
}

.single-post-area .blog-author a {
    display: inline-block
}

.single-post-area .blog-author p {
    margin-bottom: 0
}

.single-post-area .blog-author h4 {
    font-size: 16px
}

.single-post-area .navigation-area {
    border-bottom: 1px solid #eee;
    padding-bottom: 30px;
    margin-top: 55px
}

.single-post-area .navigation-area p {
    margin-bottom: 0
}

.single-post-area .navigation-area h4 {
    font-size: 18px;
    line-height: 25px;
    color: #2a2a2a
}

.single-post-area .navigation-area .nav-left {
    text-align: left
}

.single-post-area .navigation-area .nav-left .thumb {
    margin-right: 20px;
    background: #000
}

.single-post-area .navigation-area .nav-left .thumb img {
    -webkit-transition: all .3s ease 0s;
    -moz-transition: all .3s ease 0s;
    -o-transition: all .3s ease 0s;
    transition: all .3s ease 0s
}

.single-post-area .navigation-area .nav-left .lnr {
    margin-left: 20px;
    opacity: 0;
    -webkit-transition: all .3s ease 0s;
    -moz-transition: all .3s ease 0s;
    -o-transition: all .3s ease 0s;
    transition: all .3s ease 0s
}

.single-post-area .navigation-area .nav-left:hover .lnr {
    opacity: 1
}

.single-post-area .navigation-area .nav-left:hover .thumb img {
    opacity: .5
}

@media(max-width:767px) {
    .single-post-area .navigation-area .nav-left {
        margin-bottom: 30px
    }
}

.single-post-area .navigation-area .nav-right {
    text-align: right
}

.single-post-area .navigation-area .nav-right .thumb {
    margin-left: 20px;
    background: #000
}

.single-post-area .navigation-area .nav-right .thumb img {
    -webkit-transition: all .3s ease 0s;
    -moz-transition: all .3s ease 0s;
    -o-transition: all .3s ease 0s;
    transition: all .3s ease 0s
}

.single-post-area .navigation-area .nav-right .lnr {
    margin-right: 20px;
    opacity: 0;
    -webkit-transition: all .3s ease 0s;
    -moz-transition: all .3s ease 0s;
    -o-transition: all .3s ease 0s;
    transition: all .3s ease 0s
}

.single-post-area .navigation-area .nav-right:hover .lnr {
    opacity: 1
}

.single-post-area .navigation-area .nav-right:hover .thumb img {
    opacity: .5
}

@media(max-width:991px) {
    .single-post-area .sidebar-widgets {
        padding-bottom: 0
    }
}

.comments-area {
    background: 0 0;
    border-top: 1px solid #eee;
    padding: 45px 0;
    margin-top: 50px
}

@media(max-width:414px) {
    .comments-area {
        padding: 50px 8px
    }
}

.comments-area h4 {
    margin-bottom: 35px;
    color: #2a2a2a;
    font-size: 18px
}

.comments-area h5 {
    font-size: 16px;
    margin-bottom: 0
}

.comments-area a {
    color: #2a2a2a
}

.comments-area .comment-list {
    padding-bottom: 48px
}

.comments-area .comment-list:last-child {
    padding-bottom: 0
}

.comments-area .comment-list.left-padding {
    padding-left: 25px
}

@media(max-width:413px) {
    .comments-area .comment-list .single-comment h5 {
        font-size: 12px
    }
    .comments-area .comment-list .single-comment .date {
        font-size: 11px
    }
    .comments-area .comment-list .single-comment .comment {
        font-size: 10px
    }
}

.comments-area .thumb {
    margin-right: 20px
}

.comments-area .thumb img {
    width: 70px;
    height: 70px;
    border-radius: 50%
}

.comments-area .date {
    font-size: 14px;
    color: #ccc;
    margin-bottom: 0;
    margin-left: 20px
}

.comments-area .comment {
    margin-bottom: 10px;
    color: #777
}

.comments-area .btn-reply {
    background-color: transparent;
    color: #888;
    padding: 5px 18px;
    font-size: 14px;
    display: block;
    font-weight: 400;
    -webkit-transition: all .3s ease 0s;
    -moz-transition: all .3s ease 0s;
    -o-transition: all .3s ease 0s;
    transition: all .3s ease 0s
}

.comments-area .btn-reply:hover {
    color: var(--water-blue)
}

.comment-form {
    border-top: 1px solid #eee;
    padding-top: 45px;
    margin-top: 50px;
    margin-bottom: 20px
}

.comment-form .form-group {
    margin-bottom: 30px
}

.comment-form h4 {
    margin-bottom: 40px;
    font-size: 18px;
    line-height: 22px;
    color: #2a2a2a
}

.comment-form .name {
    padding-left: 0
}

@media(max-width:767px) {
    .comment-form .name {
        padding-right: 0;
        margin-bottom: 1rem
    }
}

.comment-form .email {
    padding-right: 0
}

@media(max-width:991px) {
    .comment-form .email {
        padding-left: 0
    }
}

.comment-form .form-control {
    border: 1px solid #f0e9ff;
    border-radius: 5px;
    height: 48px;
    padding-left: 18px;
    font-size: 13px;
    background: 0 0
}

.comment-form .form-control:focus {
    outline: 0;
    box-shadow: none
}

.comment-form .form-control::placeholder {
    font-weight: 300;
    color: #999
}

.comment-form .form-control::placeholder {
    color: #777
}

.comment-form textarea {
    padding-top: 18px;
    border-radius: 12px;
    height: 100% !important
}

.comment-form::-webkit-input-placeholder {
    font-size: 13px;
    color: #777
}

.comment-form::-moz-placeholder {
    font-size: 13px;
    color: #777
}

.comment-form:-ms-input-placeholder {
    font-size: 13px;
    color: #777
}

.comment-form:-moz-placeholder {
    font-size: 13px;
    color: #777
}

.button {
    display: inline-block;
    background-color: var(--water-blue);
    color: #fff;
    border-radius: 3px;
    padding: 8px 25px;
    text-transform: uppercase;
    -webkit-transition: all .3s ease 0s;
    -moz-transition: all .3s ease 0s;
    -o-transition: all .3s ease 0s;
    transition: all .3s ease 0s
}

@media(min-width:600px) {
    .button {
        padding: 12px 45px
    }
}

.button:hover {
    background-color: #201aa3;
    color: #fff
}

.button-contactForm {
    border-radius: 5px
}

.button-shadow {
    box-shadow: 0 10px 20px 0 rgba(181, 176, 255, .2)
}

button.button {
    border: 0
}

.footer-area {
    background: var(--water-blue);
    padding-top: 60px
}

@media(min-width:1000px) {
    .footer-area {
        padding-top: 50px;
        padding-bottom: 20px
    }
}

.footer-area .footer-nav li {
    margin-top: 18px
}

.footer-area .footer-nav li a {
    color: #fff
}

.footer-area .footer-nav li a:hover {
    color: var(--water-blue)
}

.footer-area .primary-btn {
    background-color: var(--water-blue);
    line-height: 42px;
    padding-left: 30px;
    padding-right: 60px;
    border-radius: 25px;
    border: none;
    color: #fff;
    display: inline-block;
    font-weight: 500;
    position: relative;
    -webkit-transition: all .3s ease 0s;
    -moz-transition: all .3s ease 0s;
    -o-transition: all .3s ease 0s;
    transition: all .3s ease 0s;
    cursor: pointer;
    text-transform: uppercase;
    position: relative
}

.footer-area .primary-btn:focus {
    outline: 0
}

.footer-area .primary-btn span {
    color: #fff;
    position: absolute;
    top: 50%;
    transform: translateY(-60%);
    right: 30px;
    -webkit-transition: all .3s ease 0s;
    -moz-transition: all .3s ease 0s;
    -o-transition: all .3s ease 0s;
    transition: all .3s ease 0s
}

.footer-area .primary-btn:hover {
    background-color: var(--water-blue);
    box-shadow: none;
    color: #fff
}

.footer-area .primary-btn:hover span {
    color: #fff;
    right: 20px
}

.footer-area .primary-btn.white {
    border: 1px solid #fff;
    color: #fff
}

.footer-area .primary-btn.white span {
    color: #fff
}

.footer-area .primary-btn.white:hover {
    background: #fff;
    color: var(--water-blue)
}

.footer-area .primary-btn.white:hover span {
    color: var(--water-blue)
}

.footer-area h6 {
    text-transform: uppercase;
    color: #fff;
    margin-bottom: 25px;
    font-size: 20px;
    font-weight: 700
}

.copy-right-text a,
.copy-right-text i {
    color: var(--water-blue)
}

.instafeed {
    margin: -5px
}

.instafeed li {
    overflow: hidden;
    width: 25%;
    margin-bottom: 0 !important
}

.instafeed li img {
    margin: 5px
}

.footer-social {
    text-align: right
}

.footer-social a {
    width: 20px;
    width: 40px;
    display: inline-table;
    height: 40px;
    text-align: center;
    padding-top: 10px;
    -webkit-transition: all .3s ease 0s;
    -moz-transition: all .3s ease 0s;
    -o-transition: all .3s ease 0s;
    transition: all .3s ease 0s
}

.footer-social a:hover i {
    color: var(--water-blue)
}

.footer-social i,
.footer-social span {
    font-size: 14px;
    color: rgba(221, 221, 221, .5);
    -webkit-transition: all .3s ease 0s;
    -moz-transition: all .3s ease 0s;
    -o-transition: all .3s ease 0s;
    transition: all .3s ease 0s
}

@media(max-width:991.98px) {
    .footer-social {
        text-align: left;
        margin-top: 20px
    }
}

.single-footer-widget {
    color: #fff
}

.single-footer-widget input {
    line-height: 38px;
    border: none;
    background: 0 0;
    border: 1px solid #333;
    font-weight: 400;
    color: #999;
    padding-left: 20px;
    width: 80%;
    font-size: 14px
}

.single-footer-widget input:focus {
    outline: 0;
    box-shadow: none
}

.single-footer-widget input::placeholder {
    font-weight: 400
}

.single-footer-widget .bb-btn {
    background-color: var(--water-blue);
    color: #fff;
    font-size: 13px;
    font-weight: 300;
    border-radius: 0;
    cursor: pointer
}

@media(max-width:960px) {
    .single-footer-widget .nw-btn {
        margin-top: 20px
    }
    .single-footer-widget .newsletter {
        padding-bottom: 40px
    }
}

.single-footer-widget .info {
    position: absolute;
    margin-top: 3px;
    color: #fff;
    font-size: 14px
}

.single-footer-widget .info.valid {
    color: green
}

.single-footer-widget .info.error {
    color: var(--water-blue)
}

.single-footer-widget::-moz-selection {
    background-color: var(--water-blue);
    color: #888
}

.single-footer-widget::selection {
    background-color: var(--water-blue);
    color: #888
}

.single-footer-widget::-webkit-input-placeholder {
    color: #888;
    font-weight: 300
}

.single-footer-widget:-moz-placeholder {
    color: #888;
    opacity: 1;
    font-weight: 300
}

.single-footer-widget::-moz-placeholder {
    color: #888;
    opacity: 1;
    font-weight: 300
}

.single-footer-widget:-ms-input-placeholder {
    color: #888;
    font-weight: 300
}

.single-footer-widget::-ms-input-placeholder {
    color: #888;
    font-weight: 300
}

@media(max-width:991.98px) {
    .single-footer-widget {
        margin-bottom: 45px
    }
}

.single-footer-widget a,
.single-footer-widget p {
    color: var(--white);
    font-size: 14px
}

.single-footer-widget ul li {
    margin-bottom: 10px
}

.single-footer-widget ul li a {
    color: #777;
    -webkit-transition: all .3s ease 0s;
    -moz-transition: all .3s ease 0s;
    -o-transition: all .3s ease 0s;
    transition: all .3s ease 0s
}

.single-footer-widget ul li a:hover {
    color: var(--water-blue)
}

.footer-bottom {
    padding: 18px 0;
    margin-top: 30px;
    border-top: 1px solid rgba(255, 255, 255, .1)
}

@media(min-width:1000px) {
    .footer-bottom {
        margin-top: 70px
    }
}

.footer-text a,
.footer-text i {
    color: var(--water-blue)
}

::selection {
    background: 0 0
}

::-moz-selection {
    background: 0 0
}

.wrapper-demo {
    font-weight: 400
}

.wrapper-demo:after {
    clear: both;
    content: "";
    display: table
}

.wrapper-dropdown-5 {
    position: relative;
    margin: 0 auto;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 1px 0 rgba(0, 0, 0, .2);
    cursor: pointer;
    outline: 0;
    -webkit-transition: all .3s ease-out;
    -moz-transition: all .3s ease-out;
    -ms-transition: all .3s ease-out;
    -o-transition: all .3s ease-out;
    transition: all .3s ease-out
}

.wrapper-dropdown-5 .dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 1;
    background: #343434;
    border-radius: 0 0 5px 5px;
    border: 1px solid rgba(0, 0, 0, .2);
    border-top: none;
    border-bottom: none;
    list-style: none;
    -webkit-transition: all .3s ease-out;
    -moz-transition: all .3s ease-out;
    -ms-transition: all .3s ease-out;
    -o-transition: all .3s ease-out;
    transition: all .3s ease-out;
    max-height: 0;
    overflow: hidden
}

.wrapper-dropdown-5 .dropdown li {
    padding: 0 10px
}

.wrapper-dropdown-5 .dropdown li a:hover {
    background: #343434
}

.wrapper-dropdown-5 .dropdown li a {
    display: block;
    text-decoration: none;
    color: #333;
    padding: 10px 0;
    transition: all .3s ease-out;
    border-bottom: 1px solid #636363
}

.wrapper-dropdown-5 .dropdown li a .accounts {
    background: var(--white)
}

.wrapper-dropdown-5 .dropdown li a .accounts:hover {
    border: 1px solid var(--water-blue)
}

.wrapper-dropdown-5 .dropdown li:last-of-type a {
    border: none
}

.wrapper-dropdown-5 .dropdown li i {
    margin-right: 5px;
    color: inherit;
    vertical-align: middle
}

.wrapper-dropdown-5 .dropdown li:hover a {
    color: #57a9d9
}

.wrapper-dropdown-5.active {
    border-radius: 5px 5px 0 0;
    background: #4cbeff;
    box-shadow: none;
    border-bottom: none;
    color: #fff
}

.wrapper-dropdown-5.active .dropdown {
    border-bottom: 1px solid rgba(0, 0, 0, .2);
    max-height: 400px
}

.majikonnect {
    font-family: Proxima Nova !important;
    font-weight: 700 !important;
    font-size: 1.7em !important;
}