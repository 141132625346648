/* .tab-header .MuiButtonBase-root {
    text-transform: none;
    padding: 12px;
    margin-right: 4px;
    font-size: 13px;
    color: #212121;
}

.tab-header .Mui-selected {
    background: #fff;
    border-radius: 10px 10px 0px 0px;
}

.tab-header .MuiTabs-indicator {
    border-radius: 2px 2px 0px 0px;
} */

.tab-header .MuiButtonBase-root {
    text-transform: none;
    margin: 8px;
    border-radius: 6px;
 
}
