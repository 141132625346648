.accounts-profile {
    border-radius: 5px;
    box-shadow: 1.5px 2px 5.5px 0 rgb(23 135 224 / 50%);
  
}
.ac-details {
    color:#4156a6;
}

.custom-input {
    border-radius: 15px;
    border: 1px #0c8af8 solid;
    padding: 4px 12px;
    background-color: white;
    height: 22px;
    box-shadow: 0 0 2px #0074d9;
  }
  
  .custom-input:focus {
    outline: none;
    border: 1px solid #0c8af8;
    box-shadow: 0 0 10px 2px #0074d9;
  }

.datep.MuiPickersPopper-root{
    margin-top: 60px !important;
  }

.form-control {
  display: block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: transparent;
  background-clip: padding-box;
  border-bottom: 1px solid #e4e4e4;
  border-radius: 0;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  border-top: none;
  border-left: none;
  border-right: none;
}