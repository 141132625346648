.account-menu {
    position: relative;
}

.account-menu .dropdown-menu {
    position: absolute;
    left: -4.65rem;
    border: none;
    box-shadow: rgb(0 0 0 / 20%) 0px 2px 1px -1px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px;
}

.account-menu .dropdown-menu .header {
    padding: 0.25rem 1.5rem;
}
.account-menu .dropdown-menu .header p {
   margin-bottom: .3rem;
}
.account-menu .dropdown-menu .header p {
    margin-bottom: .15rem;
 }

.account-menu .dropdown-menu a {
    text-transform: none;
}

.account-menu .dropdown-toggle::after{
    display: none;
}